<template>
  <div>
    <!-- list filters -->
    <v-card>
      <v-card-title class="pb-2">
        Tareas
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-1 me-3"
            @click="pushNew"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Nuevo</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-1"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-divider class="mt-2"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0 pl-0 pt-0">
        <v-col cols="12">
          <filter-by v-model="newItems" :filterFields="filterFields" :filterLists="filterLists"></filter-by>
        </v-col>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="newItems"
        :options.sync="options"
        :loading="loading"
        :expanded.sync="expanded"
        show-expand
        single-expand
        item-key="id"
      >
        <template #[`item.project_stage`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="35"
              :color="resolveStatusVariant(item.project_stage)"
              :class="`me-3`"
            >
              <img
                :style="'width:20px; height:20px;'"
                :src="require(`@/assets/images/my-images/${resolveStatusIcon(item.project_stage)}`)"
              />
            </v-avatar>
            <span class="text-capitalize">{{ resolveStatusText(item.project_stage) }}</span>
          </div>
        </template>

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              :headers="tableColumns2"
              :items="item.tasks"
              dense
              disable-filtering
              disable-pagination
              disable-sort
            >
              <template #[`item.priority`]="{item}">
                <v-chip
                  small
                  label
                  :color="resolvePriorityChip(item.priority)"
                >
                  <span :style="{ color: resolvePriorityVariant(item.priority) }">{{ resolvePriorityText(item.priority)}}</span>
                </v-chip>
              </template>

              <template #[`item.amount`]="{item}">
                {{ item.amount + (item.unit_measure === 'day' ? ' días' : ' horas')}}
              </template>

              <template #[`item.status_task`]="{item}">
                <v-chip
                  small
                  :color="resolveStatusTaskVariant(item.status_task)"
                  :class="`${resolveStatusTaskVariant(item.status_task)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize"
                  outlined
                >
                  {{ resolveStatusTaskText(item.status_task)}}
                </v-chip>
              </template>


              <template #[`item.budget`]="{item}">
                <td style="min-width: 150px;">
                  {{ item.budget | currency }}
                </td>
              </template>

              <template #[`item.start_date`]="{item}">
                <td style="min-width: 100px;">
                  {{ dating(item.start_date) }}
                </td>
              </template>

              <template #[`item.resource`]="{item}">
                <div class="d-flex align-center justify-center">
                <!-- delete -->
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="showResources($event, item.resource)"
                      >
                        <v-icon size="18">
                          {{ icons.mdiSendCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver recursos</span>
                  </v-tooltip>
                </div>
              </template>


              <template #[`item.actions`]="{item}">
                <div class="d-flex align-center justify-center">
                  <v-menu
                    bottom
                    left
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="setOne(item)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="18"
                          class="me-2"
                        >
                          {{ actionOptions[0].icon }}
                        </v-icon>
                        <span>{{ actionOptions[0].title }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item

                    >
                      <v-list-item-title>
                        <v-icon
                          size="18"
                          class="me-2"
                        >
                          {{ actionOptions[1].icon }}
                        </v-icon>
                        <span>{{ actionOptions[1].title }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon
                          size="18"
                          class="me-2"
                        >
                          {{ actionOptions[2].icon }}
                        </v-icon>
                        <span>{{ actionOptions[2].title }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                    >
                      <v-list-item-title>
                        <v-icon
                          size="18"
                          class="me-2"
                        >
                          {{ actionOptions[3].icon }}
                        </v-icon>
                        <span>{{ actionOptions[3].title }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                </div>
              </template>
            </v-data-table>
          </td>
        </template>

      </v-data-table>
    </v-card>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="500"
      :position-x="resources.x"
      :position-y="resources.y"
      offset-y
    >
      <v-card class="py-1 elevation-6" outlined>
        <v-card-title class="pa-3">
          Recursos
        </v-card-title>
        <v-card-text v-if="resources.items && resources.items.length > 0">
          <v-list dense disabled>
            <v-list-item-group
              light
            >
              <v-list-item
                v-for="(item, i) in resources.items"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ item.fullname ? icons.mdiAccount : ''}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.fullname"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-text v-else>
          No tiene recursos asociados
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiAccount,
  mdiExportVariant,
  mdiPlus,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiFileChartOutline,
  mdiTextBoxCheckOutline,
  mdiAccountGroupOutline,
  mdiSendCircleOutline,
} from '@mdi/js'
import FilterBy from '@/components/widgets/filter/FilterBy.vue'
import { ref, watch } from '@vue/composition-api'
import useTasksList from './useTasksList'
import useProjectsList from '@/views/projects/projects-list/useProjectsList'
import { avatarText, dating } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'
import { useRouter } from '@core/utils'

export default {
  components: { FilterBy },
  setup() {
    const { router } = useRouter()
    const menu = ref(false)
    const resources = ref({})
    const projectId = ref(0)
    const projectSurveyId = ref(0)
    const expanded = ref([])
    const {
      taskListTable,
      tableColumns,
      tableColumns2,
      loading,
      options,
      fetchTaskList,
      filterFields,
      filterLists,
      resolvePriorityChip,
      resolvePriorityText,
      resolvePriorityVariant,
      resolveStatusTaskText,
      resolveStatusTaskVariant,
    } = useTasksList()

    const { resolveStatusVariant, resolveStatusIcon, resolveStatusText } = useProjectsList()
    const actionOptions = [
      { title: 'Editar', icon: mdiPencilOutline },
      { title: 'Informe', icon: mdiFileChartOutline },
      { title: 'Tareas', icon: mdiTextBoxCheckOutline },
      { title: 'Comité', icon: mdiAccountGroupOutline },
    ]
    const newItems = ref([])
    const { rootThemeClasses } = useVuetify()

    fetchTaskList()

    watch(
      taskListTable,
      () => {
        newItems.value = taskListTable.value.map((item, i) => {
          let ele = {
            ...item,
            id: i + 1,
          }
          return ele
        })
      },
      {
        deep: true,
      },
    )

    const updated = () => {
      fetchTaskList()
    }

    const setOne = item => {
      router.push({ name: 'tasks-id', params: { id: item.task_id } })
    }

    const showResources = (e, item) => {
      resources.value.items = item ? item : []
      resources.value.x = e.x
      resources.value.y = e.y
      menu.value = true
    }

    const pushNew = () => {
      router.push('/proyectos-tareas/nuevo')
    }

    return {
      actionOptions,
      avatarText,
      dating,
      expanded,
      filterFields,
      filterLists,
      loading,
      menu,
      newItems,
      options,
      projectId,
      projectSurveyId,
      pushNew,
      resolvePriorityChip,
      resolvePriorityText,
      resolvePriorityVariant,
      resolveStatusVariant,
      resolveStatusTaskVariant,
      resolveStatusIcon,
      resolveStatusTaskText,
      resolveStatusText,
      resources,
      rootThemeClasses,
      setOne,
      showResources,
      tableColumns,
      tableColumns2,
      updated,
      icons: {
        mdiAccount,
        mdiDotsVertical,
        mdiExportVariant,
        mdiPlus,
        mdiSendCircleOutline,
      },
    }
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
