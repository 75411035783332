import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiAccountGroupOutline,
  mdiAccountCogOutline,
  mdiDnsOutline,
  mdiAccountCashOutline,
  mdiAccountEditOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useTasksList() {

  const taskListTable = ref([])
  const tableColumns = [
    { text: 'PROYECTO', value: 'project', sortable: false },
    { text: 'ETAPA', value: 'project_stage', sortable: false },
    { text: 'CATEGORÍA', value: 'categories',  sortable: false},
    /* { text: 'CÓDIGO', value: 'code', sortable: false },
    { text: 'PRIORIDAD', value: 'priority', sortable: false },
    { text: 'DESCRIPCIÓN', value: 'description', sortable: false },
    { text: 'ESTIMADO', value: 'amount', sortable: false },
    { text: 'EJECUTADO', value: 'total_executed', sortable: false },
    { text: 'ESTADO', value: 'status_task', sortable: false },
    { text: 'PRESUPUESTO', value: 'budget', sortable: false },
    { text: 'INICIA', value: 'start_date', sortable: false },
    { text: 'RECURSO', value: 'resource', sortable: false },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    }, */
  ]
  const tableColumns2 = [
    { text: 'CÓDIGO', value: 'code', sortable: false },
    { text: 'PRIORIDAD', value: 'priority', sortable: false },
    { text: 'DESCRIPCIÓN', value: 'description', sortable: false },
    { text: 'ESTIMADO', value: 'amount', sortable: false },
    { text: 'EJECUTADO', value: 'total_executed', sortable: false },
    { text: 'ESTADO', value: 'status_task', sortable: false },
    { text: 'PRESUPUESTO', value: 'budget', sortable: false },
    { text: 'INICIA', value: 'start_date', sortable: false },
    { text: 'RECURSO', value: 'resource', sortable: false },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const filterLists = ref([])
  filterLists.value =  [{
    value: 'priority',
    list: [
      {text: 'Muy alta', value: 'highest'},
      {text: 'Alta', value: 'high'},
      {text: 'Normal', value: 'normal'},
      {text: 'Baja', value: 'low'},
      {text: 'Muy baja', value: 'lowest'},
    ]
  }]


  const filterFields = [
    { text: 'PROYECTO', value: 'project', type: 'text' },
    { text: 'ETAPA', value: 'project_stage', type: 'text' },
    { text: 'CATEGORÍA', value: 'categories', type: 'text'},
    /* { text: 'CÓDIGO', value: 'code', type: 'number' },
    { text: 'PRIORIDAD', value: 'priority', type: 'lookup'},
    { text: 'DESCRIPCIÓN', value: 'description', type: 'text' },
    { text: 'ESTIMADO', value: 'amount', type: 'number' },
    { text: 'EJECUTADO', value: 'total_executed', type: 'number' },
    { text: 'ESTADO', value: 'status_task', type: 'text' },
    { text: 'PRESUPUESTO', value: 'budget', type: 'number' },
    { text: 'INICIA', value: 'start_date', type: 'date' }, */
  ]

  const roleOptions = [
    { text: 'Administrador', value: 'admin' },
    { text: 'Gestor', value: 'project_manager' },
    { text: 'Comité Evaluador', value: 'commitee' },
    { text: 'Auditor', value: 'auditor' },
    { text: 'Patrocinador', value: 'sponsor' },
    { text: 'Miembro', value: 'team_member' },
  ]

  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })

  // fetch data
  const fetchTaskList = () => {
    loading.value = true
    store
      .dispatch('tasks/fetchTaskList')
      .then(response => {

      const data = ref([])
        if (response) {
          data.value = response.projects
          taskListTable.value = data.value ? data.value : []
          loading.value = false
        } else {
          taskListTable.value = []
          loading.value = false
        }
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }


  watch([], () => {
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePriorityVariant = status => {
    if (status === 'normal') return '#F57F17'
    if (status === 'low') return '#1B5E20'
    if (status === 'lowest') return '#0D47A1'
    if (status === 'high') return '#E65100'
    if (status === 'highest') return '#B71C1C'
  }

  const resolvePriorityChip = status => {
    if (status === 'normal') return '#FFF9C4'
    if (status === 'low') return '#C8E6C9'
    if (status === 'lowest') return '#BBDEFB'
    if (status === 'high') return '#FFE0B2'
    if (status === 'highest') return '#FFCDD2'
  }

  const resolveStatusTaskVariant = status => {
    if (status === 'open') return 'error'
    if (status === 'in_progress') return 'warning'
    if (status === 'done_closed') return 'success'
  }

  const resolvePriorityText = status => {
    if (status === 'normal') return 'Normal'
    if (status === 'low') return 'Baja'
    if (status === 'lowest') return 'Muy baja'
    if (status === 'high') return 'Alta'
    if (status === 'highest') return 'Muy alta'
  }

  const resolveStatusTaskText = status => {
    if (status === 'open') return 'Abierto'
    if (status === 'in_progress') return 'En progreso'
    if (status === 'done_closed') return 'Completado'
  }


  return {
    fetchTaskList,
    filterFields,
    filterLists,
    taskListTable,
    tableColumns,
    tableColumns2,
    loading,
    options,
    resolvePriorityChip,
    resolvePriorityText,
    resolveStatusTaskText,
    resolvePriorityVariant,
    resolveStatusTaskVariant,
    roleOptions,
  }
}
